<template>
  <div class="ui fluid container">
    <p>
      Exchanging Microsoft authorization...
    </p>
    <p v-if="step > 1">
      Getting profile from Microsoft...
    </p>
    <p v-if="step > 2">
      Signing in...
    </p>
    <span v-if="response">{{ JSON.stringify(response) }}</span>
  </div>
</template>

<script>
import auth from "@/auth";
import axios from "axios";

export default {
  name: "LoginMicrosoft",

  data() {
    return {
      step: 0,
      response: null
    };
  },

  async created() {
    if (this.$route.query.code) {
      const redirect_uri =
        process.env.NODE_ENV === "development"
          ? "http://localhost:8080/auth-login/"
          : "https://om.pixelshive.com/auth-login/";
      this.step = 1;
      let bodyFormData = new FormData();
      bodyFormData.append("client_id", "79e5d74f-55be-4ba8-8203-171b57d78c23");
      //bodyFormData.append("client_id", "293508ad-6665-4f40-abe7-2ec6c300d960");
      bodyFormData.append("scope", "user.read");
      bodyFormData.append("code", this.$route.query.code);
      bodyFormData.append("redirect_uri", redirect_uri);
      bodyFormData.append("grant_type", "authorization_code");
      bodyFormData.append("code_verifier", window.sessionStorage.getItem("cv"));
      // bodyFormData.append(
      //   "client_secret",
      //   "L01w9.-wElTEnnJ.U1k1gu03_z7-lXKf.r"
      // );

      let access_token;

      try {
        const response = await axios({
          method: "post",
          url: "https://login.microsoftonline.com/common/oauth2/v2.0/token",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" }
        });

        access_token = response.data.access_token;
      } catch (error) {
        this.response = error;
        return;
      }

      this.step = 2;
      try {
        const response = await this.$http.post("microsoft/", {
          token: access_token
        });

        auth.login(response.data.username, response.data.access_token);
        this.$router.push({ name: "Dashboard" });
      } catch (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.non_field_errors) {
              this.$router.push({
                name: "Login",
                query: {
                  error: error.response.data.non_field_errors[0]
                }
              });
            }
          }
        } else {
          this.$router.push({
            name: "Login",
            query: {
              error:
                "Network error. Please check your conectivity or try again later."
            }
          });
        }
        this.response = error;
        console.log(error);
        return;
      }
    }
  }
};
</script>

<style scoped>
.login.column {
  background-color: #c3cfd8;
}

.grid {
  height: 100vh;
}
</style>
